.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login_wrapper_main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.register_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--mb-6) 0;
  background-image: url(../../assets/images/bg/try.png);
  background-attachment: fixed;
  background-size: cover;
}

.login_wrapper {  
  width: 100%;
  min-height: 86vh;
  display: flex;
  justify-content: center;
  /*padding: var(--mb-6) 0;*/
  flex-direction: column;
  align-items: center;
  /*background-image: url(../../assets/images/bg/try.png);
  background-attachment: fixed;
  background-size: cover;*/
}

.customOverlay {
    color: transparent;
    backdrop-filter: blur(8px);
}

.customModal {
    width: 90%;
    background-color: #230374 !important;    
    border-radius: 20px;
    border: 1px solid #e1e2e2;
    scroll-behavior: smooth;
}

.register_container {   
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  
}

.boldText {
  font-weight: 700;
  margin-top: var(--mb-2);
}

.normalText {
  margin: var(--mb-3) 0;
}

.subHeading {
  letter-spacing: 0.1rem;
  margin: var(--mb-4) 0 var(--mb-2) 0;
  font-family: var(--primaryFontBold);
}

.registerFormContainer {
  flex-basis: 50%;
  border-radius: 20px;
  justify-content: center;
  margin-top: 20px;
  /*background-image: linear-gradient(to bottom right, #440bd48e, #ff207991);*/
  background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
  box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*background-color: #000;*/
  border-radius: 1rem;
  padding: var(--mb-4) var(--mb-4);
}

.recaptcha_container {
  margin: var(--mb-2);
  margin-bottom: 20px;
}

.recaptcha {
  display: inline-block !important;
}

.btn_s {
  margin-top: 25px;
  width: 70%;
  background-color: #f83606;
  color: whitesmoke;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  border: none;
  box-shadow: 2px 2px #e1e2e2;
}

.btn_s:hover{
  color:#f83606;
  background-color: white;
  box-shadow: 3px 3px #f83606;
}

.googleIcon {
  border: 0.5px solid transparent;
  border-radius: 50%;
  padding: var(--mb-1);
  width: 50px;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}


/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .googleIcon:hover {
    transform: scale(1.2);
  }

}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .register_container {
    flex-direction: column;
    width: 100%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .registerFormContainer {
    width: 60%;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .registerFormContainer {
    width: 90%;
  }
}
