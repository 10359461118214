.container {
    width: 70%;
    margin: auto;
    padding-right: 50px;
    padding-top: 20px;
}
.outercontainer{
    display: flex;
}
.item{
    padding: 20px 60px;
    width: 70%;
}

.graph{
    height: 520px;
    width:100%;
    margin-top: 10px;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
    .outercontainer{
        flex-direction: column;
    }
    .item{
        padding: 20px 10px;
        width: 100%;
    }
    .container{
        padding-right: 10px;
    }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}