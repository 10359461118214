.active-modal {
    overflow-y: hidden;
}

.btns {
    display: flex;
    justify-content: space-between;
    /* padding: 5px 30px; */
}

.lbtn {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.mbtn{
    display: flex;
    flex: 1;
    justify-content: center;
}

.rbtn {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modalContent {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);;
    color: white;
    padding: 14px 28px;
    border-radius: 25px;
    max-width: 600px;
    min-width: 300px;
}
.modalContent label{
    font-size:1.1rem;
    margin: 20px 0px;
}

.closeModal {
    float: right;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    color: white;
    background-color: rgb(243, 2, 2);
    border-radius: 7px;
    border: none;
    outline: none;
}
.modalContent td{
    padding: 10px 0px;
}

.closeModal:hover{
    cursor: pointer;
}

#stocks {
    padding: 10px;
    height: 30px;
    width: 70%;
    background-color: black;
    border: none;
    outline: none;
    border-radius: 5px;
    color: white;
}
#stocks::-webkit-outer-spin-button,
#stocks::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media only screen and (min-width: 1024px) {

    .btns {
        display: flex;
        justify-content: space-between;
        padding: 5px 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .btns {
        display: flex;
        justify-content: space-between;
        padding: 5px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .btns {
        display: flex;
        justify-content: space-between;
    }
}

