.button {
    background-color: rgb(255, 115, 0); 
    border: none;
    color: white;
    padding: 15px 35px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    border-radius: 20px;
  }
  
 .button:hover{
     font-weight: bolder;
    cursor: pointer;
 }

  /* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {

    /* .button:hover{
        background-color: white;
        color: rgb(0, 156, 222);
        font-weight: bold;
    } */
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
   
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .button{
        padding: 12px 30px;
    }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
    .button{
        padding: 10px 25px;
    }
}