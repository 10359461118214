.news{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    
}
.newsfeed{
    color: black;
    display:inline-flex;
    align-self: center;
    width: 100%;
    max-width: 85%;
    padding: 20px;
    /* background-image: linear-gradient(to bottom right, #d50b0b98, #20d2ff9f); */
    background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
    box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
    border-radius: 20px;
    margin-top: 20px;
}
.newsfeed h1{
    text-align: center;
}
hr{
    border-style: dotted none none none;
    border-width: 3px;
    width: 95%;
    margin: auto;
}
.newsfeedchild{
    height: 78vh;
    overflow-y: scroll;
    display:inline-flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    padding-right: 20px ;
    border: none;
    outline: none;
    line-height: 2;
    
}
 .newsfeedchild::-webkit-scrollbar
{
  width: 5px;
  background-color: rgba(218, 224, 224, 0.13);
  border-radius: 5px;
}

.newsfeedchild::-webkit-scrollbar-thumb
{
  width:10px;
  border-radius: 5px;
  background-color: rgba(245, 194, 171, 0.479);
} 
.newsfeedchild div{
    margin-bottom: 5px;
    font-size: large;
}
.scrollDiv{
    margin-top: 15px;
}
/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
    .newsfeedchild{
        height: 55vh;
    }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
