.wrapper{
  min-height: 86vh;
}
.maincontainer{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

.profilecontainer{
  background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
  box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  min-width: 40%;
  padding: 20px;
  align-self: flex-start;
  margin-bottom: 30px;
}

.itemRow {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.7rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.555);
}
.itemRow:last-child{
  border-bottom: none;
}

.itemHeader {
  color: #000000;
  flex-basis: 40%;
  text-align: left;
}

.itemContent{
  flex-basis: 60%;
  text-align: left;
}

.stocktable{
  background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
  box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  color: #000000;
  font-weight: bolder;
  padding: 0;
  border-spacing: 0;
  width: 40%;
  font-size: 1.1rem;
}

.stocktable tr:nth-child(even){
  background-color: rgba(255, 255, 255, 0.13);
}

.stocktable thead{
  background-color: rgba(0, 0, 0, 0.247);
  padding: 0;
  border-radius: 20px;
  border-bottom: 1px solid white;
}

.stocktable thead th{
  padding: 20px 30px;
  border-bottom: 1px solid white;
  font-size: 1.2rem;
}

.stocktable thead th:first-child{
  border-top-left-radius: 20px;
}

.stocktable thead th:last-child{
  border-top-right-radius: 20px;
}

.stocktable tr:last-child td:last-child{
  border-bottom-right-radius: 20px;
}

.stocktable tr:last-child td:first-child{
  border-bottom-left-radius: 20px;
}

.stocktable td{
  padding: 20px 30px;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .profilecontainer,.stocktable{
    width: 70%;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .profilecontainer,.stocktable{
    width: 90%;
  }
  .itemRow{
    padding:  0.5rem;
  }
  .stocktable thead th,.stocktable td{
    padding: 20px 10px;
  }
  .itemHeader{
    margin-right: 15px;
  }
}
