.footerbox{
    padding: 2rem 10rem;
    background-color: #fbeed79b;
    margin-top: 2rem;
    color:black;
    font-weight: bolder;
}

a{
    color: #f73c24;
}

@media only screen and (max-width: 767px) {
    .footerbox{
        padding: 2rem;
    }
  }
  