/* Media Queries */


.head{
    font-family: sans-serif;
    padding-top: 10px;
    padding-bottom: 20px;
}
.item{
  width:50%;
  padding: 30px 20px 0;
}

.itemN{
  width:50%;
  padding: 30px 20px 0;
}

.uppercontainer{
  display: flex;
  flex-direction: row;
}
  
.container {
    width: 70%;
    margin: auto;
  }


.card {
    color: black;
    font-weight: 900;
    height:70px;
    width: 70%;
    background-color: aliceblue;
    text-align: left;
    border-radius: 0.5rem;
    box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgb(122, 121, 121);
    margin: 0px auto 20px;
  }
  

  .card img {
    background-color: aliceblue;
    border-radius: 0.5rem;
    height: 70px;
    padding: 20px;
    float: left;
  }

  .card > span{
    font-size: 1rem;
    display: inline-block;
    margin-top: 25px;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(230, 224, 224, 0.938);
    transform: scale(1.1);
}
.stocksheading{
  color: black;
}
  
/* Above Tablet */
@media only screen and (min-width: 1024px) {
    .container{
        grid-template-columns: 31% 31% 31%;
        row-gap: 40px;
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    .item{
      width:100%;
    }
    .itemN{
      width:100%;
      height: 50%;
    }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .container{
    grid-template-columns: auto;
    row-gap: 40px;
    /* column-gap: 10px; */
}
.item{
  width:100%;
}
  .uppercontainer{
    flex-direction: column;
  }

  .stocksheading{
    padding-top: 25px;
  }
  .card img{
    padding:20px;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .container{
        grid-template-columns: auto auto;
        row-gap: 70px;
        width: 70%;
        column-gap: 40px;
    }
    .item{
      width:100%;
    }
    .itemN{
      width:100%;
      height: 50%;
    }
    .card{
      padding-bottom: 0.5rem;
      width: 100%;
    }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
    .container{
        grid-template-columns: auto;
        row-gap: 50px;
        width: 90%;
    }
    .item{
      width:100%;
    }
    .itemN{
      width:100%;
      height: 50%;
    }
    .card > span{
      font-size: 1rem;
    }

    .card{
      padding-bottom: 0.5rem;
      width: 100%;
    }


}
