.mainbox{
  min-height: 86vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.contentbox{
    display:inline-flex;
    align-self: center;
    width: auto;
    max-width: 85%;
    padding: 30px;
    /*background-image: linear-gradient(to bottom right, #440bd48e, #ff207991);*/
    /*background-image: linear-gradient(to bottom right, #440bd4be, #ff2079c7);*/
    /* background-image: linear-gradient(to bottom right, #0a62dd98, #20d2ff9f); */
    background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
    box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
    border-radius: 20px;
    margin-top: 20px;
}
.subcontentbox{
  height: 50vh;
  overflow-y: scroll;
  display:inline-flex;
  flex-direction: column;
  align-self: center;
  width: auto;
  padding-right: 20px ;
  border: none;
  outline: none;
}
.subcontentbox::-webkit-scrollbar
{
  width: 10px;
  background-color: rgba(45, 45, 45, 0.145);
  border-radius: 5px;
}

.subcontentbox::-webkit-scrollbar-thumb
{
	border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.449);
}
.instructionbox{
    margin: 10px;
    padding: 10px 30px;
    display: inline-flex;
    flex-direction: row;
    align-self: flex-start;
    align-items: center;
    border-radius: 0 20px 20px 0;
}
.instruction{
    padding-left: 15px;
    text-align: left;
    color: #000000;
    font-size: 1.1rem;
    letter-spacing: 0.07rem;
    font-weight: bolder;
}

.icon{
    min-width: 20px;
    max-width: 20px;
    height: 20px;
}
.instructionbox span{
  display: none;
  font-size: 1.3rem;
  padding-right: 5px;
}
.instructionbox:nth-child(odd) span{
  color:rgb(0, 231, 0);
}
.instructionbox:nth-child(even) span{
  color:rgb(231, 15, 0);
}
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .instructionbox{
    padding: 10px 0px;
    margin: 0;
  }
  .icon{
    display: none;
  }
  .instructionbox span{
    display: inline;
  }
  .instruction{
    padding-left: 2px;
    font-size: 1rem;
  }
  .contentbox{
    padding: 20px;
  }
}