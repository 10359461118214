.mainbox{
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.contentbox{
  display:inline-flex;
  align-self: center;
  width: auto;
  max-width: 70%;
  padding: 30px;
  background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
  box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
  border-radius: 20px;
  margin-top: 40px;
}
.subcontentbox{
display:inline-flex;
flex-direction: column;
align-self: center;
width: auto;
padding-right: 20px ;
border: none;
outline: none;
}


.subcontentbox h2 {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 15px;
}



@media only screen and (max-width: 767px) {
  .contentbox{
    display:inline-flex;
    align-self: center;
    width: auto;
    max-width: 85%;
    padding: 10px;
    background-image: linear-gradient(to bottom right, #d40b0b98, #20d2ff9f);
    box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .subcontentbox{
    overflow-y: scroll;
  }
  .subcontentbox h2 {
    text-align: center;
    margin: 0;
    padding-bottom: 1rem;
  }
    .subcontentbox::-webkit-scrollbar {
      width: 10px;
      background-color: rgba(218, 224, 224, 0.274);
      border-radius: 5px;
    }
  
    .subcontentbox::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgb(211, 201, 201);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contentbox{
    display:inline-flex;
    align-self: center;
    width: auto;
    max-width: 85%;
    padding: 30px;
    background-image: linear-gradient(to bottom right, #440bd4be, #ff2079c7);
    box-shadow: 0 4px 8px 5px rgba(255, 245, 245, 0.5), 0 6px 20px 3px rgba(245, 245, 245, 0.6);
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .subcontentbox h2 {
    text-align: left;
    padding: 0;
    padding-bottom: 1rem; 
  }
}
